// 获取签到规则列表
import { request } from './request'

export function getPageDefineList (params) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/page-define/list',
    method: 'get',
    params
  })
}

export function delPageDefineRequest (id) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: `/page-define/${id}`,
    method: 'delete'
  })
}

export function addPageDefineRequest (info) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/page-define/add',
    method: 'post',
    data: { ...info }
  })
}

export function updatePageDefineRequest (info) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/page-define/update',
    method: 'post',
    data: { ...info }
  })
}

export function getPageShowList (params) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/page-show/list',
    method: 'get',
    params
  })
}

export function delPageShowRequest (id) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: `/page-show/${id}`,
    method: 'delete'
  })
}

export function addPageShowRequest (info) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/page-show/add',
    method: 'post',
    data: { ...info }
  })
}

export function updatePageShowRequest (info) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/page-show/update',
    method: 'post',
    data: { ...info }
  })
}

export function sortPageShowRequest (info) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/page-show/sort',
    method: 'post',
    data: { ...info }
  })
}
