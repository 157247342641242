<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>营销管理</template>
      <template v-slot:secondMenu>页面定义</template>
    </breadcrumb-nav>
    <el-card>
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="margin: 10px 0px"
      @click="showDialog"
    >添加
    </el-button>
    <el-table style="width: 100%" border :data="list">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column label="类型" width="200px" align="center">
        <template slot-scope="scope">{{ scope.row.type | typeLabel }}</template>
      </el-table-column>
      <el-table-column prop="name" label="名称" width="200px">
      </el-table-column>
      <el-table-column prop="pageAddr" label="地址">
      </el-table-column>
      <!--<el-table-column prop="params" label="参数">
        <template slot-scope="scope">{{ scope.row.params || '无' }}</template>
      </el-table-column>-->
      <el-table-column prop="prop" label="操作" width="200px">
        <template slot-scope="{row}">
          <el-button
            type="warning"
            icon="el-icon-edit"
            size="mini"
            @click="update(row)"
          >修改</el-button
          >
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="del(row)"
          >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.page"
      :page-sizes="[5, 10]"
      :page-size="listQuery.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </el-card>
    <el-dialog
      :title="form.id ? '修改配置' : '添加配置'"
      :visible.sync="dialogFormVisible"
    >
      <el-form style="width: 80%" :model="form" :rules="rules" ref="form">
        <el-form-item label="类型" label-width="100px" prop="type">
          <el-select v-model="form.type" style="width:100%" placeholder="请选择页面类型">
            <el-option
              v-for="item in pageTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" label-width="100px" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="地址" label-width="100px" prop="pageAddr">
          <el-input v-model="form.pageAddr"></el-input>
        </el-form-item>
        <!--<el-form-item label="参数" label-width="100px" prop="params">
          <el-input v-model="form.params"></el-input>
        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdate"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { addPageDefineRequest, delPageDefineRequest, getPageDefineList, updatePageDefineRequest } from '../../../network/market'

export default {
  name: 'PageDefine',
  components: {
    BreadcrumbNav
  },
  filters: {
    typeLabel (value) {
      switch (value) {
        case 1:
          return '应用内页面'
        case 2:
          return 'h5分享页面'
      }
    }
  },
  data () {
    return {
      listQuery: {
        page: 1,
        size: 10
      },
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      // 收集签到规则信息
      form: {
        type: '',
        name: '',
        pageAddr: '',
        params: ''
      },
      // 表单验证规则
      rules: {
        // require:必须要校验字段（前面五角星有关系）  message 提示信息    trigger:用户行为设置（事件的设置:blur、change）
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        pageAddr: [{ required: true, message: '请输入地址', trigger: 'blur' }]
      },
      // 页面类型
      pageTypes: [{
        label: '应用内页面',
        value: 1
      }, {
        label: 'h5分享页面',
        value: 2
      }]
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getList()
  },
  methods: {
    getList () {
      getPageDefineList(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取列表失败', 'error')
        }
        this.list = result.data.list
        this.total = result.data.total
      })
    },
    update (row) {
      console.log('update row:', row)
      this.dialogFormVisible = true
      this.form = { ...row }
    },
    del (row) {
      console.log('del row:', row)
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delPageDefineRequest(row.id).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除配置失败', 'error')
          }
          this.alertMessage('删除配置成功', 'success')
          this.getList()
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    showDialog () {
      this.dialogFormVisible = true
      this.form = { type: '', name: '', pageAddr: '', params: '' }
    },
    update2Srv () {
      updatePageDefineRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改配置失败', 'error')
        }
        this.alertMessage('修改配置成功', 'success')
        this.getList()
      })
    },
    add2Srv () {
      addPageDefineRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加配置失败', 'error')
        }
        this.alertMessage('添加配置成功', 'success')
        this.getList()
      })
    },
    addOrUpdate () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        if (this.form.id) {
          this.update2Srv()
        } else {
          this.add2Srv()
        }
      })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getList()
    }
  }
}
</script>

<style scoped>

</style>
